<template>
  <div>

    <v-card tile class="card_section">
      <v-card-title>Zmień hasło</v-card-title>
      <v-card-text>
        
       <v-form
          ref="form" 
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" md="4">
              
              <v-text-field
                v-model="old_pass"
                label="Stare hasło"
                color="primary"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :rules="$store.state.rules.not_null"
              ></v-text-field>

            </v-col>
            <v-col cols="12" md="4">

              <v-text-field
                v-model="new_pass"
                label="Nowe hasło"
                color="primary"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :rules="$store.state.rules.not_null"
              ></v-text-field>

            </v-col>
            <v-col cols="12" md="4">

              <v-text-field
                v-model="new_pass_repeat"
                label="Powtórz nowe hasło"
                color="primary"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :rules="$store.state.rules.not_null"
              ></v-text-field>

            </v-col>
          </v-row>
       </v-form>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          tile
          @click="changePassword()"
        >
          Zmień hasło
        </v-btn>
      </v-card-actions>
    </v-card>
    
  </div>
</template>

<script>
export default {
  props: {
  },
  data: () => ({
    valid: false,
    show: false,
    
    old_pass: '',
    new_pass: '',
    new_pass_repeat: ''
  }),
  methods: {
    changePassword(){
      if(this.$refs.form.validate()) {

        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("password_old", this.old_pass);
        formData.append("password", this.new_pass);
        formData.append("password_confirm", this.new_pass_repeat);

        this.$axios({url: this.$store.state.api +'/auth/change_password', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            this.$router.push('/panel');
            this.$store.commit("snackbar", {
              text: "Zmieniono hasło",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then(()=> {
            this.$store.commit('loader_off');
          })
      } else this.$store.commit('wrong_form_snackbar');
    },
  },
  mounted(){
    // this.$store.commit('loader');

    // this.$store.commit('loader_off');
  }
};
</script>

<style lang="scss" scoped>
</style>