<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />
    
    <v-card>
      <Password/>
    </v-card>

  </div>
</template>

<script>
import Password from '@/components/Settings/Password.vue';

export default {
  components: {
    Password
  },
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Ustawienia',
        disabled: true,
        to: '/ustawienia',
      },
    ],
  }),
  methods: {

  },
  mounted(){
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
}
.paid {
  background-color: green;
  color: white;
  padding: 5px 10px;
  text-align: center;
}
.not_paid {
  background-color: red;
  color: white;
  padding: 5px;
  text-align: center;
}
</style>